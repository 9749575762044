import logo from "../assets/images/logo.png";

export default function Hero() {
  return (
    <div className="relative pt-24">
      <main className="mx-auto max-w-7xl px-4">
        <div className="text-center">
          <img
            src={logo}
            width="175px"
            className="mx-auto"
            alt="Primal Pick'Em Logo"
          />
          <h1 className="text-4xl font-extrabold text-gray-100 sm:text-5xl md:text-6xl font-serif">
            <span className="block xl:inline">Primal Pick'Em</span>{" "}
            <span className="block text-indigo-600">Leaderboards</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-200 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Find the top predictors and see your $HOOP rewards for each game.
          </p>
        </div>
      </main>
    </div>
  );
}
