import { useEffect, useState } from "react";
import Hero from "./components/Hero";
import Selector from "./components/Selector";
import Table from "./components/Table";
import Layout from "./components/Layout";
import total from "./data/total.json";
import game1 from "./data/game1.json";
import game2 from "./data/game2.json";
import game3 from "./data/game3.json";
import game4 from "./data/game4.json";
import game5 from "./data/game5.json";
import game6 from "./data/game6.json";
import wnba from "./data/wnba.json";
import nba3weeks from "./data/nba3weeks.json";

export const GAMES = [
  { id: 0, name: "Totals", json: total },
  { id: 6, name: "WNBA", json: wnba },
  { id: 6, name: "NBA First 3 Weeks", json: nba3weeks },
  { id: 1, name: "Game 1", json: game1 },
  { id: 2, name: "Game 2", json: game2 },
  { id: 3, name: "Game 3", json: game3 },
  { id: 4, name: "Game 4", json: game4 },
  { id: 5, name: "Game 5", json: game5 },
  { id: 6, name: "Game 6", json: game6 },
];

function App() {
  const [game, setGame] = useState(GAMES[0]);
  const [search, setSearch] = useState("");
  const [curPage, setCurPage] = useState(1);

  // Set page to 0 when table data changes
  useEffect(() => {
    setCurPage(1);
  }, [game]);

  return (
    <Layout>
      <Hero />
      <Selector
        value={game}
        setValue={setGame}
        search={search}
        setSearch={(e) => setSearch(e.target.value)}
      />
      <Table
        data={game.json}
        search={search}
        curPage={curPage}
        setCurPage={setCurPage}
      />
    </Layout>
  );
}

export default App;
