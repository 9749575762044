import { useEffect, useState } from "react";

export default function Table({ data, search, curPage, setCurPage }) {
  const pageLength = 25; // How many rows in each page
  const [pageData, setPageData] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  // Refine data with the current search term to get Display Data
  useEffect(() => {
    if (search) {
      setDisplayData(
        data.filter(
          (person) =>
            person.user.toLowerCase().includes(search.toLowerCase()) ||
            person.address.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else setDisplayData(data);
  }, [data, search]);

  // Use the DisplayData to show the current PageData
  useEffect(() => {
    const start = (curPage - 1) * pageLength;
    const end =
      curPage * pageLength < displayData.length
        ? curPage * pageLength
        : displayData.length;
    setPageData(displayData.slice(start, end));
  }, [curPage, displayData]);

  const nextPage = () => {
    const maxPage = Math.ceil(displayData.length / pageLength);
    if (curPage < maxPage) setCurPage(curPage + 1);
  };

  const prevPage = () => {
    if (curPage > 1) setCurPage(curPage - 1);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Rank
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      $HOOP
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Wallet
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Dino's Held
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {pageData
                    .sort((a, b) => a.rank - b.rank)
                    .map((person, i) => (
                      <tr key={`${person.user}-${i}`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {person.rank}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.user}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.hoop}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {`${person.address.slice(
                            0,
                            6
                          )} . . . ${person.address.slice(-4)}`}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.dinos}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {(curPage - 1) * pageLength + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {curPage * pageLength < displayData.length
                        ? curPage * pageLength
                        : displayData.length}
                    </span>{" "}
                    of <span className="font-medium">{displayData.length}</span>{" "}
                    results
                  </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                  <button
                    onClick={() => prevPage()}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => nextPage()}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
